import React, { useState } from "react";
import { Link } from "react-router-dom";
// import Header from "../Layout/Header";
// import Header2 from "../Layout/Header2";
import RightContainer from "./RightContainer";

function IntroScreen() {
  const [box, setBox] = useState("0");
  const [otp, setOtp] = useState("");

  return (
    <div>
      <div id="root">
        {/* <!------Nav Menu Start------> */}

        {/* <div id="menufade" onclick="closeMenu()" className=""></div> */}

        {/* <!------Nav Menu End------>

		<!------Hedar Start------> */}

        <div className="leftContainer">
          {/* <Header2 /> */}
          <div className="main-area" style={{ paddingTop: "130px" }}>
            <div style={{ overflowY: "hidden" }}>
              {/* <div className="splash-overlay"></div> */}
              {/* <div className="splash-screen">
                <figure>
                  <img
                    width="100%"
                    src="../assest/image/global-gameSheetSplash.png"
                    alt=""
                  />
                </figure>
                <figure>
                  <img
                    width="100%"
                    src="../assest/image/landingNew.png"
                    alt=""
                  />
                </figure>
              </div> */}
              <img width="100%" src="../assest/image/landingNew.png" alt="" />
              <p>
                This Game involves an element of financial risk and may be
                addictive. Please Play responsibly and at your own risk.
              </p>
              <div className="login-footer">
                <div className="row">
                  <div className="col-9">
                    <Link
                      to="/login"
                      class="bg-dark refer-button cxy mt-2 send-otp"
                      style={{ width: "100%" }}
                    >
                      Play Now
                    </Link>
                  </div>
                  <div className="col-2">
                    <a
                      class="bg-primary shadow-lg rounded-circle d-flex align-items-center justify-content-center text-white"
                      href="#/support"
                      style={{ height: "60px", width: "60px" }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 16 16"
                        width="30"
                        height="30"
                        fill="currentColor"
                      >
                        <path d="M8 1a5 5 0 0 0-5 5v1h1a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V6a6 6 0 1 1 12 0v6a2.5 2.5 0 0 1-2.5 2.5H9.366a1 1 0 0 1-.866.5h-1a1 1 0 1 1 0-2h1a1 1 0 0 1 .866.5H11.5A1.5 1.5 0 0 0 13 12h-1a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h1V6a5 5 0 0 0-5-5z"></path>
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="divider-y"></div>
        <RightContainer />
      </div>
    </div>
  );
}

export default IntroScreen;
