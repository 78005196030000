export const URL = {
  // dev

  // API_BASE_URL: "http://localhost:5600",
  API_BASE_URL: "https://khelmantri.computerclasses.in/:3015",
  // API_BASE_URL: "http://34.229.29.185:3001",
  // API_BASE_URL: "http://gamesdeveloper.in:3005",
  //
  // apipublic
  createcategories: "/apiVendor/createcategories",
  getCategory: "/publicApi/getCategory",
  getsubsubcate: "/publicApi/getsubsubcate",
  getsubsubcateHome: "/publicApi/getsubsubcateHome",
  login: "/publicApi/login",
  otpVerify: "/publicApi/otpVerify",

  getprofile: "/api/getprofile",
  addFound: "/api/addFound",
  addFound2: "/api/addFound2",
  createbattel: "/api/createbattel",
  getbattellist: "/api/getbattellist",
  joinbettel: "/api/joinbettel",
  getbattelDetails: "/api/getbattelDetails",
  submitWinner: "/api/submitWinner",
  startbettel: "/api/startbettel",
  getbattellistUser: "/api/getbattellistUser",
  getuserTransation: "/api/getuserTransation",
  createaccount: "/api/createaccount",
  updateaccount: "/api/updateaccount",
  getaccuontdetails: "/api/getaccuontdetails",
  getsetting: "/api/getsetting",
  withdrawaluserAmount: "/api/withdrawaluserAmount",
  getwithdrawallist: "/api/getwithdrawallist",
  cencelBettel: "/api/cencelBettel",
  phonePayResponse: "/api/phonePayResponse",
  checkuserName: "/api/checkuserName",
  edituserId: "/api/edituserId",
  editprofileImage: "/api/editprofileImage",
  searchuserforfriend: "/api/searchuserforfriend",
  sendfrindreq: "/api/sendfrindreq",
  frindrequestList: "/api/frindrequestList",
  Acceptreq: "/api/Acceptreq",
  friendList: "/api/friendList",
  fundTransfer: "/api/fundTransfer",
  metricsUser: "/api/metricsUser",
  getusernotification: "/api/getusernotification",
  notificationseen: "/api/notificationseen",
};
