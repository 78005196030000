import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../Layout/Header";
import SiderBar from "../Layout/SiderBar";
import { allapiAction } from "../Redux/common/action";
import RightContainer from "./RightContainer";
import moment from "moment";
import { ExportToExcel } from "./ExportToExcel";

function Trasaction() {
  const dispatch = useDispatch();
  const battel_List_users = useSelector((state) =>
    state?.allapi?.battel_List_users ? state?.allapi?.battel_List_users : {}
  );
  const get_transaction = useSelector((state) =>
    state?.allapi?.get_transaction ? state?.allapi?.get_transaction : []
  );

  const profile = useSelector((state) =>
    state?.allapi?.profile ? state?.allapi?.profile : {}
  );

  useEffect(() => {
    // battel_List
    // dispatch(allapiAction.getbattellistUser());
    dispatch(allapiAction.getuserTransation());
    return () => {};
  }, []);

  const [filter, setfilter] = useState("all");

  const tlist = useMemo(() => {
    var alltra = [];

    if (filter == "all") {
      alltra = get_transaction;
    } else {
      alltra = get_transaction?.filter((data) => {
        return data?.description == "withdrawal ";
      });

      // withdrawal
    }

    return alltra;
  }, [get_transaction, filter]);
  console.log(tlist);


  const mapdata = tlist?.map((data)=>{
    
  })
  return (
    <div>
      <div id="root">
        <SiderBar />

        <div className="leftContainer">
          <Header />
          <div className="filterBox">
            <div className="flbvcon">
              {/* <div className="col-6">
               
              </div> */}
              <div className="flbv">
                <div className="row">
                  <div className="col-2">
                    <button
                      className={
                        filter == "all" ? "btn btnfilActive" : "btn btnfil"
                      }
                      onClick={() => {
                        setfilter("all");
                      }}
                    >
                      All
                    </button>
                  </div>
                  <div className="col-6">
                    <button
                      className={
                        filter == "withdrawal"
                          ? "btn btnfilActive"
                          : "btn btnfil"
                      }
                      onClick={() => {
                        setfilter("withdrawal");
                      }}
                    >
                      Withdrawal
                    </button>
                  </div>
                  <div className="col-4">
                    <button
                      className="btn btnfild"
                      onClick={() => {
                        ExportToExcel(tlist, filter);
                      }}
                    >
                      Download
                    </button>
                  </div>
                </div>

                {/* <p>All</p> */}
              </div>
            </div>
          </div>
          <div className="main-area" style={{ paddingTop: "120px" }}>
            <div className="main-area">
              {/* <div className="w-100 py-3 d-flex align-items-center list-item"> */}

              {tlist &&
                tlist?.map((data, i) => {
                  const userbet =
                    profile?.users?._id == data?.owner?._id ? "1" : "2";
                  const userwin =
                    userbet == "1" ? data?.ownerresult : data?.playeroneresult;
                  const userwinagin =
                    userbet == "1" ? data?.playerone?.uid : data?.owner?.uid;
                  {
                    /* 
                  console.log(data?.description.includes("bet win"));
                  console.log(data); */
                  }
                  return (
                    <div className="row py-3 list-item ">
                      <div className="col-3">
                        {data?.description.includes("bet win") ? (
                          <img
                            height="31px"
                            width="31px"
                            src="../assest/image/winner.png"
                            alt=""
                          />
                        ) : data?.type == "cr" ? (
                          <img
                            height="31px"
                            width="31px"
                            src="../assest/image/arrow.png"
                            alt=""
                          />
                        ) : (
                          <img
                            height="31px"
                            width="31px"
                            src="../assest/image/down.png"
                            alt=""
                          />
                        )}
                      </div>
                      <div className="col-5 trList">
                        {/* <p > */}
                        {data?.description}
                        {/* </p> */}
                        <div className="games-section-headline">
                          {/* <p> */}{" "}
                          {moment(data?.createdAt)?.format(
                            "DD MMM YYYY hh:MM a"
                          )} 
                          {/* </p> */}
                        </div>
                      </div>
                      <div className="col-4">
                        {" "}
                        <div className=" float-right font-8">
                          <div
                            className={
                              data?.type == "cr"
                                ? "text-success"
                                : "text-danger"
                            }
                          >
                            {data?.type == "cr" ? "+" : "-"} {data?.amount}
                          </div>
                          <div
                            //   className="games-section-headline "
                            className={
                              data?.type == "cr"
                                ? "text-success games-section-headline"
                                : "text-danger games-section-headline"
                            }
                            style={{ fontSize: "0.6em" }}
                          >
                            Closing Balance: {data?.current_balance}{" "}
                          </div>
                          <div
                            //   className="games-section-headline "
                            className={
                              data?.type == "cr"
                                ? "text-success games-section-headline"
                                : "text-danger games-section-headline"
                            }
                            style={{ fontSize: "0.6em" }}
                          >
                            previous Balance: {data?.previous_balance}{" "}
                          </div>
                          {/* <picture className="ml-1 mb-1">
                            {data?.type == "cr" ? (
                              <img
                                height="21px"
                                width="21px"
                                src="../assest/image/global-rupeeIcon.png"
                                alt=""
                              />
                            ) : (
                              <img
                                height="21px"
                                width="21px"
                                src="../assest/image/rupeeicon.png"
                                alt=""
                              />
                            )}
                          </picture> */}
                        </div>
                      </div>
                      <div className="col-3 list-date">
                        <small> Transaction id</small>
                      </div>
                      <div className="col-5 trList">{data?.transaction_id}</div>
                    </div>
                  );
                })}
            </div>
            {/* {get_transaction &&
              get_transaction?.map((data, i) => {
                const userbet =
                  profile?.users?._id == data?.owner?._id ? "1" : "2";
                const userwin =
                  userbet == "1" ? data?.ownerresult : data?.playeroneresult;
                const userwinagin =
                  userbet == "1" ? data?.playerone?.uid : data?.owner?.uid;

                return (
                  <div className="main-area">
                    <div className="w-100 py-3 d-flex align-items-center list-item">
                      <div className="center-xy list-date mx-2">
                        <div>
                       
                        </div>
                        <small>
                          {" "}
                          {moment(data?.createdAt)?.format("hh:mm A")}{" "}
                        
                        </small>
                      </div>
                      <div className="list-divider-y"></div>
                      <div className="mx-3 d-flex list-body">
                        <div className="d-flex align-items-center">
                          <picture className="mr-2">
                         
                          </picture>
                        </div>
                        <div className="d-flex flex-column font-8">
                          <div>
                            Tr. No.{" "}
                            <b
                              className={
                                data?.type == "cr"
                                  ? "text-success"
                                  : "text-danger"
                              }
                            >
                              {data?.transaction_id}
                            </b>
                            .
                          </div>
                          <div className="games-section-headline">
                            {data?.description}
                          </div>
                        </div>
                      </div>
                      <div className="right-0 d-flex align-items-end pe-3 flex-column ">
                        <div className="d-flex float-right font-8">
                          <div
                            className={
                              data?.type == "cr"
                                ? "text-success"
                                : "text-danger"
                            }
                          >
                            ( {data?.type == "cr" ? "+" : "-"} )
                          </div>
                          <picture className="ml-1 mb-1">
                            {data?.type == "cr" ? (
                              <img
                                height="21px"
                                width="21px"
                                src="../assest/image/global-rupeeIcon.png"
                                alt=""
                              />
                            ) : (
                              <img
                                height="21px"
                                width="21px"
                                src="../assest/image/rupeeicon.png"
                                alt=""
                              />
                            )}
                          </picture>
                          <span className="pl-1"> {data?.amount} </span>
                        </div>
                        <div
                          //   className="games-section-headline "
                          className={
                            data?.type == "cr"
                              ? "text-success games-section-headline"
                              : "text-danger games-section-headline"
                          }
                          style={{ fontSize: "0.6em" }}
                        >
                          Closing Balance: {data?.current_balance}{" "}
                        </div>
                        <div
                          //   className="games-section-headline"
                          className={
                            data?.type == "cr"
                              ? "text-success games-section-headline"
                              : "text-danger games-section-headline"
                          }
                          style={{ fontSize: "0.6em" }}
                        >
                          previous Balance: {data?.previous_balance}{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })} */}
          </div>
        </div>
        <div className="divider-y"></div>
        <RightContainer />
      </div>
    </div>
  );
}

export default Trasaction;
