import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Header from "../Layout/Header";
import SiderBar from "../Layout/SiderBar";
import { allapiAction } from "../Redux/common/action";
import Api from "../Redux/common/api";
import { URL } from "../Redux/common/url";
import PaymentForm from "./Payment/PaymentForm";
import RightContainer from "./RightContainer";
import { useTranslation } from "react-i18next";
function Addfund() {
  const { t, i18n } = useTranslation();

  const [amount, setamount] = useState("");
  const [transaction_id, settransaction_id] = useState("test234");

  const [redirectUrl, setRedirectUrl] = useState("");
  const [error, setError] = useState("");

  const dispatch = useDispatch();
  const navigete = useNavigate();

  const get_setting = useSelector((state) =>
    state?.allapi?.get_setting ? state?.allapi?.get_setting : {}
  );




  const hendleToAddFund = async (e) => {
    if (!amount) {
      toast.error("Please enter amount");
    } else if (get_setting?.minimumrecharge > amount) {
      toast.error("minimum rechage " + get_setting?.minimumrecharge);
    } else if (get_setting?.maxmumrecharge < amount) {
      toast.error("maxmum rechage" + get_setting?.maxmumrecharge);
    } else {
      const userToken = localStorage.getItem("access_user");
      var user = JSON.parse(userToken);
      // navigete("/process?amount=" + amount + "&userID=" + user?._id);
      try {
        const token = await localStorage.getItem("access_token");
        const config = {
          headers: {
            Authorization: "Bearer " + token,
          },
        };

        const responsem = await Api.post(
          `${URL.phonePayResponse}`,
          { amount: amount },
          config
        );
        if (responsem?.data?.success) {
          setRedirectUrl(
            responsem?.data?.data?.data.instrumentResponse?.redirectInfo?.url
          );

          if (
            responsem?.data?.data?.data.instrumentResponse?.redirectInfo?.url
          ) {
            window.location.href =
              responsem?.data?.data?.data.instrumentResponse?.redirectInfo?.url;
          }
        } else {
        }
      } catch (err) {
        setError("Error: " + err.message);
      }
      // dispatch(allapiAction.addFound({ amount, transaction_id }));

      // return () => {};
    }
  };
  
  //   addFound

  const hendleToAddFund2 = async (e) => {
    if (!amount) {
      toast.error("Please enter amount");
    } else if (get_setting?.minimumrecharge > amount) {
      toast.error("minimum rechage " + get_setting?.minimumrecharge);
    } else if (get_setting?.maxmumrecharge < amount) {
      toast.error("maxmum rechage" + get_setting?.maxmumrecharge);
    } else {
      const userToken = localStorage.getItem("access_user");
      var user = JSON.parse(userToken);
    
      dispatch(allapiAction.addFound2({ amount, transaction_id }));
      return () => {};
    }
  };

  useEffect(() => {
    dispatch(allapiAction.getsetting());

    return () => {};
  }, []);


  return (
    <div>
      <div id="root">
        <Header />

        <div class="leftContainer">
          <SiderBar />

          <div class="main-area" style={{ paddingTop: "60px" }}>
            <div class="px-4 py-3">
              <div class="games-section">
                {/* <PaymentForm /> */}
                <div class="d-flex position-relative align-items-center">
                  <div class="games-section-title">
                    {t("addamountchoose")}

                    {/* Choose amount to add */}
                  </div>
                </div>
              </div>
              {/* <form> */}
              {/* <input
                  type="hidden"
                  name="_token"
                  value="NCOPnD33jtIlyCDzmkEneN7QmoGwdwnqR0DYiS8P"
                />
                <input
                  type="hidden"
                  class="form-control"
                  name="mobile"
                  id="mobile"
                  value="9610641351"
                  placeholder="mobile"
                />
                <input
                  type="hidden"
                  class="form-control"
                  name="vplay_id"
                  id="vplay_id"
                  value="FEQ5W606"
                  placeholder="Vplay Id"
                /> */}

              <div class="pb-3">
                <div class="MuiFormControl-root mt-4 MuiFormControl-fullWidth">
                  <div class="MuiFormControl-root MuiTextField-root">
                    <label
                      class="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink"
                      data-shrink="true"
                    >
                      {t("enteramount")}
                      {/* Enter Amount */}
                    </label>
                    <div class="MuiInputBase-root MuiInput-root MuiInput-underline jss1 MuiInputBase-formControl MuiInput-formControl MuiInputBase-adornedStart">
                      <div class="MuiInputAdornment-root MuiInputAdornment-positionStart">
                        <p class="MuiTypography-root MuiTypography-body1 MuiTypography-colorTextSecondary">
                          ₹
                        </p>
                      </div>

                      <input
                        name="amount"
                        type="number"
                        class="MuiInputBase-input"
                        id="Textfield"
                        min="10"
                        max="10000"
                        required=""
                        autocomplete="off"
                        fdprocessedid="k7wnmc"
                        value={amount}
                        onChange={(e) => {
                          setamount(e.target.value);
                        }}
                      />
                    </div>
                    <p class="MuiFormHelperText-root">
                      {" "}
                      {t("min")}: 10, {t("max")}: 10000
                    </p>
                  </div>
                </div>
                <div class="games-window">
                  <div
                    class="gameCard-container"
                    id="amount1"
                    onClick={() => {
                      setamount(100);
                    }}
                  >
                    <div class="add-fund-box">
                      <div style={{ display: "flex", alignItems: "baseline" }}>
                        <div
                          class="collapseCard-title mr-1"
                          style={{ fontSize: "0.9em" }}
                        >
                          ₹
                        </div>
                        <div
                          class="collapseCard-title"
                          style={{ fontSize: "1.5em" }}
                        >
                          100
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="gameCard-container"
                    id="amount2"
                    onClick={() => {
                      setamount(250);
                    }}
                  >
                    <div class="add-fund-box">
                      <div style={{ display: "flex", alignItems: "baseline" }}>
                        <div
                          class="collapseCard-title mr-1"
                          style={{ fontSize: "0.9em" }}
                        >
                          ₹
                        </div>
                        <div
                          class="collapseCard-title"
                          style={{ fontSize: "1.5em" }}
                        >
                          250
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="gameCard-container"
                    id="amount3"
                    onClick={() => {
                      setamount(500);
                    }}
                  >
                    <div class="add-fund-box">
                      <div style={{ display: "flex", alignItems: "baseline" }}>
                        <div
                          class="collapseCard-title mr-1"
                          style={{ fontSize: "0.9em" }}
                        >
                          ₹
                        </div>
                        <div
                          class="collapseCard-title"
                          style={{ fontSize: "1.5em" }}
                        >
                          500
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="gameCard-container"
                    id="amount4"
                    onClick={() => {
                      setamount(1000);
                    }}
                  >
                    <div class="add-fund-box">
                      <div style={{ display: "flex", alignItems: "baseline" }}>
                        <div
                          class="collapseCard-title mr-1"
                          style={{ fontSize: "0.9em" }}
                        >
                          ₹
                        </div>
                        <div
                          class="collapseCard-title"
                          style={{ fontSize: "1.5em" }}
                        >
                          1000
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="refer-footer">
                {/* <!--<button disabled class="refer-button cxy w-100 bg-secondary disabledButton">Next</button>--> */}
                <button
                  // type="submit"
                  class="disabledButton btn btn-primary btn-lg"
                  style={{ width: "100%" }}
                  fdprocessedid="zez14v"
                  onClick={(e) => {
                    hendleToAddFund(e);
                  }}
                >
                  NEXT
                </button>
              </div>
              {/* </form> */}
            </div>
          </div>
        </div>

        <div class="divider-y"></div>
        <RightContainer />
      </div>
    </div>
  );
}

export default Addfund;
