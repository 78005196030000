// import translate from "google-translate-api";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../Layout/Header";
import SiderBar from "../Layout/SiderBar";
import RightContainer from "./RightContainer";
// import { translateText } from "./Translate";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import HttpApi from "i18next-http-backend";
import Languageoption from "./language-dropdown";
import { allapiAction } from "../Redux/common/action";
import { useDispatch, useSelector } from "react-redux";
import { getUserDetail } from "../Utils/Auth/Token";
import { URL } from "../Redux/common/url";

function Friend() {
  const { t, i18n } = useTranslation();

  const handleClick = (e) => {
    console.log(e?.target?.value);
    localStorage.setItem("lang", e?.target?.value);
    i18next.changeLanguage(e?.target?.value);
  };
  const dispatch = useDispatch();

  const userDetail = getUserDetail();

  console.log(userDetail);

  const get_friend_req_list = useSelector((state) =>
    state?.allapi?.get_friend_req_list ? state?.allapi?.get_friend_req_list : []
  );

  const searchuserList = (e) => {
    dispatch(allapiAction.searchuserforfriend({ key: e }));
    return () => {};
  };

  const dendreq = (e) => {
    dispatch(allapiAction.Acceptreq({ req_id: e }));
    return () => {};
  };

  //   sendfrindreq

  useEffect(() => {
    dispatch(allapiAction.friendList({}));
    return () => {};
  }, []);

  return (
    <div>
      <div id="root">
        {/* <!------Nav Menu Start------> */}

        <SiderBar />

        {/* 
      <!------Nav Menu End------>

      <!------Hedar Start------> */}

        <div className="leftContainer">
          <Header />

          <div className="main-area" style={{ paddingTop: "60px" }}>
            <div>
              <div className="p-5">
                <h4>Friend List</h4>
              </div>

              <div className="p-5">
                {get_friend_req_list &&
                  get_friend_req_list?.map((data, i) => {
                    console.log(userDetail?._id);
                    console.log(data?.first_user?._id);
                    console.log(data);
                    return (
                      <div className="row userList">
                        <div className="col-6">
                          <div className="row">
                            <div className="col-4">
                              {" "}
                              <picture>
                                <img
                                  className="border-50"
                                  height="40px"
                                  width="40px"
                                  src={
                                    userDetail?._id !== data?.first_user?._id
                                      ? data?.first_user?.image
                                        ? URL?.API_BASE_URL +
                                          data?.first_user?.image
                                        : "../assest/image/Avatar2.png"
                                      : data?.second_user?.image
                                      ? URL?.API_BASE_URL +
                                        data?.second_user?.image
                                      : "../assest/image/Avatar2.png"
                                  }
                                  alt=""
                                />
                              </picture>
                            </div>
                            <div className="col-8">
                              <p className="trList2">
                                {userDetail?._id !== data?.first_user?._id
                                  ? data?.first_user?.uid
                                  : data?.second_user?.uid}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <p className="trList2">
                            {userDetail?._id !== data?.first_user?._id
                              ? data?.first_user?.number
                              : data?.second_user?.number}
                          </p>
                          {/* <button
                            className="center-xy sendbtn  text-uppercase "
                            onClick={() => {
                              dendreq(data?._id);
                            }}
                          >
                            Accept Request
                          </button> */}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>

        <div className="divider-y"></div>
        <RightContainer />
      </div>
    </div>
  );
}

export default Friend;
