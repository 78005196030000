import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Header from "../Layout/Header";
import SiderBar from "../Layout/SiderBar";
import { allapiAction } from "../Redux/common/action";
import RightContainer from "./RightContainer";
import { useTranslation } from "react-i18next";
function Onbid() {
  const [amount, setamount] = useState("");
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const profile = useSelector((state) =>
    state?.allapi?.profile ? state?.allapi?.profile : {}
  );
  const battel_List = useSelector((state) =>
    state?.allapi?.battel_List ? state?.allapi?.battel_List : {}
  );
  // createbattel

  const dispatch = useDispatch();

  useEffect(() => {
    // battel_List
    dispatch(allapiAction.getbattellist());
    return () => {};
  }, []);

  // useEffect(() => {
  //   // battel_List

  //   const setInt = setInterval(() => {
  //     dispatch(allapiAction.getbattellist());
  //     return () => {};
  //   }, 5000);

  //   // return clearInterval(setInt);

  //   return () => {
  //     clearInterval(setInt);
  //   };
  // }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      dispatch(allapiAction.getbattellist());
    }, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const hendleTobet = () => {

    const balencecheck = profile?.wallets?.closeBal + profile?.wallets?.winningBal;

    if (!amount) {
      toast.error("please enter amount");
    } else if (amount > balencecheck) {
      toast.error("your balance Insufficient");
    } else {
      let num = Math.random() * (99999 - 10000) + 99999;
      const roomcode = Math.floor(num);

      dispatch(allapiAction.createbattel({ amount, roomcode }));
      setamount("");
      return () => {};
    }
  };

  const hendleTojoinBettel = (e) => {
    // joinbettel
    dispatch(allapiAction.joinbettel({ _id: e }));
    return () => {};
  };

  // startbettel

  const hendletoStart = (e, i) => {
    dispatch(allapiAction.startbettel({ _id: e, user: i }));
    setTimeout(() => {
      navigate("/view-battel/" + e);
    }, 1000);
    return () => {};
  };

  const hendlecencelBettel = (e) => {
    // cencelBettel
    dispatch(allapiAction.cencelBettel({ _id: e }));
    return () => {};
  };

  return (
    <div>
      <div id="root">
        <SiderBar />

        <div className="leftContainer">
          <Header />
          <div className="main-area" style={{ paddingTop: "60px" }}>
            <span className="cxy battle-input-header"> {t("createB")} !</span>
            <div className="d-flex my-2 w-60 resp-r">
              {/* <form
                action="#"
                method="post"
              > */}
              <input type="hidden" name="game_url" value="Ludo Classic" />
              <input
                type="hidden"
                name="_token"
                value="pwtj6jSvstvLfqPffS28RPyLpXHzgg4znuE6vxMQ"
              />
              <div>
                <input
                  className="form-control"
                  type="tel"
                  name="amount"
                  placeholder="Amount"
                  // value=""
                  required=""
                  style={{ width: "79%" }}
                  fdprocessedid="z9u55"
                  value={amount}
                  onChange={(e) => {
                    setamount(e.target.value);
                  }}
                />
                <button
                  className="bg-green playButton cxy position-static"
                  type="submit"
                  style={{
                    margin: "-35px",
                    marginRight: "-27px",
                    float: "right",
                  }}
                  fdprocessedid="7cbymi"
                  onClick={() => {
                    hendleTobet();
                  }}
                >
                  Set
                </button>
              </div>
            </div>

            <div className="divider-x"></div>

            <div className="px-4 py-3">
              <div className="mb-2">
                <img
                  src="../assest/image/global-battleIconWhiteStroke.png"
                  width="20px"
                  alt=""
                />
                <span className="ml-2 games-section-title">{t("opne")}  Battles</span>
                <a href="#">
                  <span
                    className="games-section-headline text-uppercase position-absolute mt-2 font-weight-bold"
                    style={{ right: " 1.5rem", top: "9.7rem" }}
                  >
                    Rules
                    <img
                      className="ml-2"
                      src="../assest/image/global-grey-iButton.png"
                      alt=""
                    />
                  </span>
                </a>
              </div>
              <div id="myOpenBattles"></div>

              {/* <!--//Open Battle is here--> */}
              <div id="openBattle">
                {/* <!-- game is created--> */}
                {battel_List?.opne &&
                  battel_List?.opne?.map((data, i) => {
                    return (
                      <div
                        id="6345971c71f80dea71bf71cf"
                        className="betCard mt-1"
                      >
                        <span className="betCard-title pl-3 d-flex align-items-center justify-content-around text-uppercase">
                          CHALLENGE FROM
                          <span className="ml-1" style={{ color: "brown" }}>
                            US2BK256
                          </span>
                          {profile?.users?._id == data?.owner &&
                            data?.player2 == "true" && (
                              <a
                                href="#"
                                onClick={() => {
                                  hendletoStart(
                                    data?._id,
                                    profile?.users?._id == data?.owner
                                      ? "o"
                                      : "i"
                                  );
                                }}
                                className="btn btn-success btn-xs btn-req"
                              >
                                start
                              </a>
                            )}
                          {profile?.users?._id == data?.playerone?._id &&
                            data?.start1 == "true" && (
                              <a
                                href="#"
                                onClick={() => {
                                  hendletoStart(
                                    data?._id,
                                    profile?.users?._id == data?.owner
                                      ? "o"
                                      : "i"
                                  );
                                }}
                                className="btn btn-success btn-xs btn-req"
                              >
                                start
                              </a>
                            )}
                          {profile?.users?._id == data?.owner &&
                            data?.player2 == "false" && (
                              <a
                                href="#"
                                onClick={(e) => {
                                  hendlecencelBettel(data?._id);
                                }}
                                className="btn btn-danger btn-xs btn-can"
                              >
                                Cancel
                              </a>
                            )}
                        </span>

                        <div className="d-flex pl-3 justify-content-around">
                          <div className="pr-3 pb-1">
                            <span className="betCard-subTitle">Entry Fee</span>
                            <div>
                              <img
                                src="../assest/image/global-rupeeIcon.png"
                                width="21px"
                                alt=""
                              />
                              <span className="betCard-amount">
                                {data?.amount}
                              </span>
                            </div>
                          </div>
                          <div>
                            <span className="betCard-subTitle">Prize</span>
                            <div>
                              <img
                                src="../assest/image/global-rupeeIcon.png"
                                width="21px"
                                alt=""
                              />
                              <span className="betCard-amount">
                                {data?.prize}
                              </span>
                            </div>
                          </div>
                          <div
                            style={{
                              marginTop: "17px",
                              marginLeft: "14px",
                              fontSize: "11px",
                            }}
                            align="center"
                          >
                            <div align="center">
                              {profile?.users?._id !== data?.owner &&
                                data?.player2 == "false" && (
                                  <a
                                    href="#"
                                    onClick={() => {
                                      hendleTojoinBettel(data?._id);
                                    }}
                                    className="btn btn-secondary btn-xs btn-req"
                                  >
                                    PLAY
                                  </a>
                                )}
                            </div>
                            {profile?.users?._id == data?.owner &&
                              data?.player2 == "true" && (
                                <div className="text-center col-5">
                                  <div className="pl-2">
                                    <img
                                      className="border-50"
                                      src="../assest/image/Avatar2.png"
                                      width="21px"
                                      height="21px"
                                      alt=""
                                    />
                                  </div>
                                  <div style={{ lineHeight: 1 }}>
                                    <span className="betCard-playerName">
                                      {data?.playerone?.uid}
                                    </span>
                                  </div>
                                </div>
                              )}
                            {profile?.users?._id == data?.owner &&
                              data?.player2 == "false" && (
                                <div className="text-center ">
                                  <div className="pl-2">
                                    <img
                                      className="border-50"
                                      src="../assest/image/loader.png"
                                      width="21px"
                                      height="21px"
                                      alt=""
                                    />
                                  </div>
                                  <div style={{ lineHeight: 1 }}>
                                    <span className="">Finding Player!</span>
                                  </div>
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                    );
                  })}

                {/* <style>
              .btn-req {
                margin-left: 60%;
                margin-top: 1px;
                position: absolute;
                padding: 4px;
                font-size: 9px;
                color: white;
                background-color: grey;
                font-weight: 800;
              }
              .btn-can {
                margin-left: 80%;
                margin-top: 1px;
                padding: 4px;
                position: absolute;
                font-size: 9px;
                color: white;
                font-weight: 800;
              }
              @media screen and (min-width: 393px) {
                .btn-req {
                  margin-left: 68%;
                  margin-top: 1px;
                  position: absolute;
                  padding: 4px;
                  font-size: 9px;
                  color: white;
                  background-color: grey;
                  font-weight: 800;
                }
                .btn-can {
                  margin-left: 83%;
                  margin-top: 1px;
                  padding: 4px;
                  position: absolute;
                  font-size: 9px;
                  color: white;
                  font-weight: 800;
                }
              }
            </style> */}
              </div>
            </div>
            {/* <!---------------------------
    -----------------------------
    Code For OPEN BATTLE END
    -----------------------------
    ----------------------------> */}

            <div className="divider-x"></div>

            {/* <!---------------------------
    -----------------------------
    Code For Running Battle start
    -----------------------------
    ----------------------------> */}
            <div className="px-4 py-3">
              <div className="mb-2">
                <img
                  src="../assest/image/global-battleIconWhiteStroke.png"
                  width="20px"
                  alt=""
                />
                <span className="ml-2 games-section-title">
                {t("running")}  Battles{" "}
                </span>
              </div>

              <div id="myRunningBattles"></div>

              <div id="runningBattle">
                {/* <!-- battle running--> */}

                {battel_List?.running &&
                  battel_List?.running?.map((data, i) => {
                    const yourBets =
                      profile?.users?._id?.match(data?.owner?._id) ||
                      profile?.users?._id?.match(data?.playerone?._id)
                        ? true
                        : false;
                    return (
                      <div
                        id="633c3b4e85a962efab2f95db"
                        className="betCard mt-1"
                      >
                        <div className="d-flex">
                          <span className="betCard-title pl-3 d-flex align-items-center text-uppercase">
                            PLAYING FOR
                            <img
                              className="mx-1"
                              src="../assest/image/global-rupeeIcon.png"
                              width="21px"
                              alt=""
                            />
                            {data?.amount}
                          </span>

                          <div className="d-flex align-items-center text-uppercase">
                            <span className="ml-auto mr-3">
                              {yourBets && (
                                <a
                                  // href="#"
                                  onClick={() => {
                                    navigate("/view-battel/" + data?._id);
                                  }}
                                  className="btn btn-info btn-sm"
                                  style={{ padding: "3px", fontSize: "11px" }}
                                >
                                  {/* View */}
                                  {t("view")}
                                </a>
                              )}
                            </span>
                          </div>
                          <div className="betCard-title d-flex align-items-center text-uppercase">
                            <span className="ml-auto mr-3">
                              PRIZE
                              <img
                                className="mx-1"
                                src="../assest/image/global-rupeeIcon.png"
                                width="21px"
                                alt=""
                              />
                              {data?.prize}
                            </span>
                          </div>
                        </div>

                        <div className="py-1 row">
                          <div className="pr-3 text-center col-5">
                            <div className="pl-2">
                              <img
                                className="border-50"
                                src="../assest/image/Avatar2.png"
                                width="21px"
                                height="21px"
                                alt=""
                              />
                            </div>
                            <div style={{ lineHeight: 1 }}>
                              <span className="betCard-playerName">
                                {data?.owner?.uid}
                              </span>
                            </div>
                          </div>
                          <div className="pr-3 text-center col-2 cxy">
                            <div>
                              <img
                                src="../assest/image/versus.png"
                                width="21px"
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="text-center col-5">
                            <div className="pl-2">
                              <img
                                className="border-50"
                                src="../assest/image/Avatar2.png"
                                width="21px"
                                height="21px"
                                alt=""
                              />
                            </div>
                            <div style={{ lineHeight: 1 }}>
                              <span className="betCard-playerName">
                                {data?.playerone?.uid}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>

        <div className="divider-y"></div>
        <RightContainer />
      </div>
    </div>
  );
}

export default Onbid;
