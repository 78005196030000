import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const AuthAdmin = ({ cmp, baseUrl }) => {
  const navigate = useNavigate();

  let Cmp = cmp;

  useEffect(() => {
    if (!localStorage.getItem("access_token")) {
      navigate("/intro");
    }
  }, []);

  return (
    <>
      <>
        <Cmp baseUrl={baseUrl} />
      </>
    </>
  );
};

export default AuthAdmin;
