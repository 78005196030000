import {
  ACCOUNT_DETAILS,
  BATTEL_DETAILS,
  BATTEL_LIST,
  BATTEL_LIST_USERS,
  GET_FRIEND_REQ_LIST,
  GET_METRICS,
  GET_NOTIFICATION,
  GET_SETTING,
  GET_TRANSATION,
  GET_USER_LIST,
  GET_WITHDRWAL_LIST,
  LOADING,
  PROFILE,
  SIDERBAR_BOS,
} from "../common/constant";

const initialState = {
  loading: false,
  sideBar_Box: false,
  all_categories_List: [],
  get_transaction: [],
  get_user_list: [],
  get_friend_req_list: [],
  get_notification: [],
  profile: {},
  battel_List: {},
  battel_Details: {},
  battel_List_users: {},
  account_details: {},
  get_setting: {},
  get_metrics: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOADING:
      return { ...state, loading: action.payload };
    case PROFILE:
      return { ...state, profile: action.payload };
    case SIDERBAR_BOS:
      return { ...state, sideBar_Box: action.payload };
    case BATTEL_LIST:
      return { ...state, battel_List: action.payload };
    case BATTEL_LIST_USERS:
      return { ...state, battel_List_users: action.payload };
    case BATTEL_DETAILS:
      return { ...state, battel_Details: action.payload };
    case ACCOUNT_DETAILS:
      return { ...state, account_details: action.payload };
    case GET_SETTING:
      return { ...state, get_setting: action.payload };
    case GET_TRANSATION:
      return { ...state, get_transaction: action.payload };
    case GET_WITHDRWAL_LIST:
      return { ...state, get_withdrwal_list: action.payload };
    case GET_USER_LIST:
      return { ...state, get_user_list: action.payload };
    case GET_FRIEND_REQ_LIST:
      return { ...state, get_friend_req_list: action.payload };
    case GET_METRICS:
      return { ...state, get_metrics: action.payload };
    case GET_NOTIFICATION:
      return { ...state, get_notification: action.payload };

    // case OTP_BOX_FORGOT:
    //   return { ...state, serach_pro_pub: action.payload };

    default:
      return state;
  }
};
